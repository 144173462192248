<template>
	<div :class="isAdded ? 'active' : ''" class="fake-container">
		<div @click="checkType" class="text-container">
			<button v-if="!isAdded" @click="showPopUpEdit" class="edit"><img src="/img/edit.svg" alt=""></button>
			
			<p class="calcul">
				{{ clonedDescription }}
			</p>
			<p> <b>Correction</b> <br>
				<i>{{ clonedCorrection }}</i>
			</p>

		</div>
		<div class="add-container" :class="isAdded ? 'added' : ''">
			<p>{{ clonedTitle }}</p>
			<button @click="add()" class="submit edit">
				<span v-if="!isAdded"> + Ajouter</span>
				<span v-else> Ajouté</span>
			</button>
		</div>
		<Teleport to="body">
			<PopUpEditAiGeneratedQuestion v-if="isPopUpEditVisible" @close="isPopUpEditVisible = false" :question="{description:clonedDescription, title:clonedTitle, chapter, level, customId,correction:clonedCorrection}" @editQuestion="editQuestion" />
		</Teleport>
	</div>
</template>
<script>
import { createQuestionService } from '../../services/questionsService'
import PopUpEditAiGeneratedQuestion from '../PopUp/Question/PopUpEditAiGeneratedQuestion.vue'

export default{
	components: {
		PopUpEditAiGeneratedQuestion
	},
	props: {
		description: String,
		title: String,
		chapter: Number,
		level: Number,
		customId: String,
		correction: String
	},
	data(){
		return {
			isAdded: false,
			isPopUpEditVisible: false,
			clonedDescription: '',
			clonedTitle: '',
		}
	},
	methods:{
		async editQuestion(question){
			this.clonedDescription = question.description
			this.clonedTitle = question.title
			this.clonedCorrection = question.correction
		},
		showPopUpEdit(){
			this.isPopUpEditVisible = true
		},
		
		async add(){
			if(this.isAdded) return
			const formdata = new FormData()
			formdata.append('description', this.clonedDescription)
			formdata.append('title', this.clonedTitle)
			formdata.append('chapter', this.chapter)
			formdata.append('level', this.level)
			formdata.append('teacher', this.$store.state.user.user)
			formdata.append('correction', this.clonedCorrection)

			await createQuestionService(formdata)
			this.isAdded = true
			this.$emit('questionAdded')

		}
	},
	created(){
		this.clonedDescription = this.description
		this.clonedTitle = this.title
		this.clonedCorrection = this.correction
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/admin/ai-generated-flash.scss';

</style>
