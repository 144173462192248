
<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-edit-ai-generated">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<form @submit.prevent="submit">
				<div class="input-container">
					<label for="Title">Title</label>
					<input
						type="text"
						v-model="clonedQuestion.title"
						id="Title"
						placeholder="Title"
					/>
				</div>
				<div class="input-container">
					<label for="Description">Texte </label>
					<textarea
						type="text"
						class="form-control md-editor"
						v-model="clonedQuestion.description"
						id="Description"
						placeholder="Description"
						rows="10"
					>
					</textarea>
				</div>
				<div class="input-container">
					<label for="Correction">Correction </label>
					<textarea
						type="text"
						class="form-control md-editor"
						v-model="clonedQuestion.correction"
						id="Correction"
						placeholder="Correction"
						rows="10"
					>
					</textarea>
				</div>
					
				<button class="submit dark">Valider</button>
			</form>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	emits: ['close'],
	props: {
		question: Object,
	},
	data() {
		return {
			clonedQuestion: {},
		}
	},
	
	methods: {
		submit(){
			this.$emit('editQuestion', this.clonedQuestion)
			this.$emit('close')
		}
	},
	

	created() {
		this.clonedQuestion = JSON.parse(JSON.stringify(this.question))
	},

}
</script>

<style scoped lang="scss">
.pop-up-edit-ai-generated{
	width: 40vw;
	height: fit-content;
	position: relative;
	padding: 4rem;

	textarea{
		background-color: #E5EBFF;
		border: none;
		border-radius: 15px;
		padding: 1rem;
		resize: none;
	}

	button{
		margin-inline: auto;
	}
}
</style>
